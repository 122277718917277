import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Logger } from "@/utils/logger";
import { components } from "@/types/deviceDefinitionSpec";
import { ComClass, ComClassItem } from "@/types/front";

export const ComClassRepository = (_$axios: NuxtAxiosInstance) => ({
  get(classNameList: string[]): { [key: string]: ComClass } {
    const vueName = "composables/repositories/comClassRepository.ts";

    const comClassList = classNameList.reduce((acc, className) => {
      let comClass: components["schemas"]["class"];
      try {
        // MEMO: ディレクトリパス部分は変数にすると正常に読み込めなくなるためハードコードとする
        comClass = require(`@/static/device-definitions/classes/${className}.yaml`);
        acc[comClass.id] = {
          id: comClass.id,
          sourceType: comClass.source_type,
          protocol: comClass.protocol,
          items: comClass.item?.map((item) => convertItem(item)) || [],
          itemPlans: comClass.item_plan?.map((item) => convertItem(item)) || []
        };
      } catch (e) {
        // ファイルがない場合はログを出力して処理を継続させる
        Logger.error(`${vueName}#get`, `FIleNotFound: @/static/device-definitions/classes/${className}.yaml`);
      }
      return acc;
    }, {} as { [key: string]: ComClass });
    return comClassList;
  }
});

const convertItem = (item: components["schemas"]["item"]): ComClassItem => {
  return {
    tagId: item.tag_id,
    label: item.label,
    unit: item.unit,
    get: item.get,
    set: item.set,
    displayType: item.display_type,
    description: item.description,
    unitMultiplier: item.unit_multiplier,
    unitLetter: item.unit_letter,
    decimalDigit: item.decimal_digit,
    aggregation: item.aggregations,
    annotations: item.annotations
  };
};
