import { NuxtAxiosInstance } from "@nuxtjs/axios";
import axios, { CancelTokenSource, AxiosRequestConfig } from "axios";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import FetchError from "@/errors/FetchError";
import { generateUrl } from "@/utils/urlUtils";
import { htmlUnescape } from "@/utils/stringUtils";
import { Logger } from "@/utils/logger";
import { PreviewTab } from "@/types/front";

export const PrivateDataPreviewRepository = ($axios: NuxtAxiosInstance) => ({
  /** getメソッド */
  async get(props: {
    endpoint: PreviewTab["endpoint"];
    params: PreviewTab["params"];
    idToken: CognitoIdToken;
    config?: AxiosRequestConfig;
  }) {
    const vueName = "composables/repositories/privateDataPreviewRepository.ts";

    const fetchConfig = {
      headers: {
        Authorization: props.idToken.getJwtToken()
      },
      ...(props.config || {})
    };

    const previewData: Array<Record<string, string | number | boolean>> = [];
    const url = htmlUnescape(generateUrl({ path: props.endpoint, query: props.params }));
    try {
      const result = await $axios.get<string>(url, fetchConfig);
      if (result.data) {
        const rows = result.data.split("\n").filter((row) => row);
        // 配列の１番目はヘッダのためアイテムがあるか判定
        if (rows.length > 1) {
          const headers = rows[0].split(",");
          for (let i = 1; i < rows.length; i++) {
            const columns = rows[i].split(",");
            // ヘッダの項目をキーに持つ連想配列形式に変換
            const item = headers.reduce((accum, current, index) => {
              accum[current] = columns[index];
              return accum;
            }, {} as Record<string, string | number | boolean>);
            // 追加
            previewData.push(item);
          }
        }
      }
    } catch (e) {
      // キャンセルか判定
      if (axios.isCancel(e)) {
        Logger.info(`${vueName}#get`, `FetchCancel: ${url}`);
        throw new FetchError(true, e, "PrivateDataPreviewRepository get method is cancel");
      } else {
        Logger.error(`${vueName}#get`, `FetchError: ${url}`);
        throw new FetchError(false, e, "PrivateDataPreviewRepository get method is error");
      }
    }
    return previewData;
  },

  /** リクエストキャンセル */
  cancel(source: CancelTokenSource, msg?: string) {
    source.cancel(msg);
  },

  /** リクエストキャンセルトークン生成 */
  createCancelToken() {
    const { CancelToken } = axios;
    return CancelToken.source();
  }
});
