//
//
//
//

import { defineComponent, onMounted } from "@vue/composition-api";
import { Auth } from "@aws-amplify/auth";
import config from "@/config";
import { getOrigin } from "@/utils/urlUtils";

export default defineComponent({
  setup() {
    const json = window.location.search.substr(1);
    const data = JSON.parse(decodeURIComponent(json));

    // localStorageをクリアする
    localStorage.clear();

    // ポータルアプリから渡されたlocalStorageをセットする
    Object.keys(data).forEach((key) => {
      localStorage.setItem(key, data[key]);
    });

    onMounted(() => {
      const completeStorageOnload = async () => {
        await Auth.currentAuthenticatedUser();
        window.parent.postMessage({ action: "storage-onload", status: "done" }, getOrigin(config.portalUrl));
      };

      completeStorageOnload();
    });

    return {};
  }
});
