import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { AxiosRequestConfig } from "axios";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import FetchError from "@/errors/FetchError";
import { Logger } from "@/utils/logger";
import { ResAdminApiGetUserV3 } from "@/types/adminApiSpec";
import { AdminApiUser } from "@/types/front";
import { generateUrl } from "@/utils/urlUtils";
import { htmlUnescape } from "@/utils/stringUtils";
import config from "@/config";

export const UserRepository = ($axios: NuxtAxiosInstance) => ({
  /** getメソッド */
  async getUser(props: { email: string; idToken: CognitoIdToken; config?: AxiosRequestConfig }) {
    const vueName = "composables/repositories/userRepository.ts";
    const fetchConfig = {
      headers: {
        Authorization: props.idToken.getJwtToken()
      },
      ...(props.config || {})
    };

    let userData: AdminApiUser | undefined;
    const url = htmlUnescape(
      generateUrl({ path: `${config.adminApi.endpoint}/getUserV3/${props.email}`, query: undefined })
    );
    try {
      const result = await $axios.get(url, fetchConfig);
      if (result.data) {
        const res = result.data as ResAdminApiGetUserV3;
        userData = {
          name: res.name,
          email: res.email,
          tenantAuth: res.tenant_auth,
          customAuth: res.custom_auth
        };
      }
    } catch (e) {
      Logger.error(`${vueName}#get`, `FetchError: ${url}`);
      throw new FetchError(false, e, "UserRepository get user is error");
    }
    return userData;
  }
});
