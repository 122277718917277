import { InjectionKey, reactive } from "@nuxtjs/composition-api";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import { CancelTokenSource, CancelToken } from "axios";
import { NuxtAppOptions } from "@nuxt/types";
import FetchError from "@/errors/FetchError";
import { OtherDataState, DatamartOtherData, PreviewTab } from "@/types/front";
import { Logger } from "@/utils/logger";

const PrivateDataStore = () => {
  const vueName = "composables/store/privateDataStore.ts";

  /** ストア内部のデータ */
  const state = reactive<OtherDataState>({
    dataList: [],
    schemaList: [],
    previewData: [],
    previewDataCancelToken: undefined
  });

  function fetchDataDefinitions(props: { app: NuxtAppOptions }) {
    const { app } = props;
    // データ取得
    const privateDataDefList = app.$repositories("privateDataDef").get();
    // ストアにデータ設定
    state.dataList = privateDataDefList;
  }

  function fetchSchemaList(props: { app: NuxtAppOptions; idToken: CognitoIdToken }) {
    // ストアデータの初期化
    state.schemaList = [];

    // ストア更新処理（データ取得含む）
    const updateState = (data: DatamartOtherData) => {
      // ストアにデータ設定
      state.schemaList.push({
        dataId: data.dataId,
        isLoading: true,
        isError: false,
        columnList: []
      });
      // APIの情報
      const schemaSource = data.catalog.schemaSource;
      // データ取得
      props.app
        .$repositories("privateDataSchema")
        .get({ schemaSource, idToken: props.idToken })
        .then((res) => {
          // ストアにデータ設定
          const schema = state.schemaList.find((schema) => schema.dataId === data.dataId);
          if (schema) {
            schema.isLoading = false;
            schema.columnList = res;
          }
        })
        .catch((e) => {
          if (e instanceof FetchError) {
            Logger.fatal(`${vueName}#fetchSchemaList`, `Error when fetch schema dataId: ${data.dataId}`, e);
          } else if (e instanceof Error) {
            Logger.fatal(`${vueName}#fetchSchemaList`, `Error when fetch schema dataId: ${data.dataId}`, e);
          } else {
            Logger.fatal(
              `${vueName}#fetchSchemaList`,
              `Error when fetch schema dataId: ${data.dataId}`,
              new Error("unknown error")
            );
          }
          // ストアにデータ設定
          const schema = state.schemaList.find((schema) => schema.dataId === data.dataId);
          if (schema) {
            schema.isLoading = false;
            schema.isError = true;
            schema.columnList = [];
          }
        });
    };

    state.dataList.forEach((data) => {
      if (data.type === "group") {
        // グループの場合
        data.dataList.forEach((d) => {
          updateState(d);
        });
      } else {
        // データの場合
        updateState(data);
      }
    });
  }

  async function fetchPreview(props: {
    app: NuxtAppOptions;
    endpoint: PreviewTab["endpoint"];
    params: PreviewTab["params"];
    idToken: CognitoIdToken;
    itemId: string;
  }) {
    // すでに実行中のFetch処理の有無を確認
    if (state.previewDataCancelToken) {
      // キャンセル処理
      props.app.$repositories("privatePreviewData").cancel(state.previewDataCancelToken as CancelTokenSource);
    }
    // キャンセルトークン生成
    state.previewDataCancelToken = props.app.$repositories("privatePreviewData").createCancelToken();

    // データ取得
    const previewData = await props.app.$repositories("privatePreviewData").get({
      ...props,
      config: { cancelToken: state.previewDataCancelToken?.token as CancelToken }
    });

    // 非表示フィールド名取得処理
    const getHideColumnNameList = (dataList: OtherDataState["dataList"]) => {
      const hideColumns: string[] = [];
      let isFound = false;
      dataList.some((d) => {
        if (d.type === "group") {
          // グループの場合は再帰的に対象データを探索して、該当データがあるまでループさせる
          const result = getHideColumnNameList(d.dataList);
          if (result.isFound) {
            hideColumns.push(...result.hideColumns);
            isFound = true;
            return true;
          }
        } else if (d.dataId === props.itemId) {
          // 対象データの場合、非表示フィールド名に追加
          hideColumns.push(...d.catalog.columnList.filter((c) => c.hide).map((c) => c.name));
          isFound = true;
          return true;
        }
        return false;
      });
      return { hideColumns, isFound };
    };
    // 非表示フィールド名一覧
    const hideColumnNameList = getHideColumnNameList(state.dataList).hideColumns;

    // ストアにデータ設定
    state.previewData = previewData.map((p) =>
      Object.keys(p).reduce((accum, current) => {
        if (!hideColumnNameList.includes(current)) {
          accum[current] = p[current];
        }
        return accum;
      }, {} as typeof previewData[number])
    );
    state.previewDataCancelToken = undefined;
  }

  return {
    data: state,
    fetchDataDefinitions,
    fetchSchemaList,
    fetchPreview
  };
};
export default PrivateDataStore;
export type PrivateDataStoreReturnType = ReturnType<typeof PrivateDataStore>;
export const PrivateDataStoreInjectionKey: InjectionKey<PrivateDataStoreReturnType> = Symbol("PrivateDataStore");
