export const OrganisumsCommonItemContentPane = () => import('../../components/organisums/common/item-content-pane.vue' /* webpackChunkName: "components/organisums-common-item-content-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsCommonLinkItemsPane = () => import('../../components/organisums/common/link-items-pane.vue' /* webpackChunkName: "components/organisums-common-link-items-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsCommonResizablePane = () => import('../../components/organisums/common/resizable-pane.vue' /* webpackChunkName: "components/organisums-common-resizable-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsCommonTabDetailPane = () => import('../../components/organisums/common/tab-detail-pane.vue' /* webpackChunkName: "components/organisums-common-tab-detail-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsCommonTabPreviewPane = () => import('../../components/organisums/common/tab-preview-pane.vue' /* webpackChunkName: "components/organisums-common-tab-preview-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsCommonTabSchemaPane = () => import('../../components/organisums/common/tab-schema-pane.vue' /* webpackChunkName: "components/organisums-common-tab-schema-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsEmsItemContentPane = () => import('../../components/organisums/ems/item-content-pane.vue' /* webpackChunkName: "components/organisums-ems-item-content-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsEmsLinkItemsPane = () => import('../../components/organisums/ems/link-items-pane.vue' /* webpackChunkName: "components/organisums-ems-link-items-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsEmsTabPreviewPane = () => import('../../components/organisums/ems/tab-preview-pane.vue' /* webpackChunkName: "components/organisums-ems-tab-preview-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsPrivateItemContentPane = () => import('../../components/organisums/private/item-content-pane.vue' /* webpackChunkName: "components/organisums-private-item-content-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsPrivateLinkItemsPane = () => import('../../components/organisums/private/link-items-pane.vue' /* webpackChunkName: "components/organisums-private-link-items-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsPrivateTabPreviewPane = () => import('../../components/organisums/private/tab-preview-pane.vue' /* webpackChunkName: "components/organisums-private-tab-preview-pane" */).then(c => wrapFunctional(c.default || c))
export const OrganisumsPrivateTabSchemaPane = () => import('../../components/organisums/private/tab-schema-pane.vue' /* webpackChunkName: "components/organisums-private-tab-schema-pane" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
