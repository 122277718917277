import { getOrigin } from "@/utils/urlUtils";
/**
 * 非同期でIframeを読み込む関数です
 * @param url 対象URL
 * @param actionName postMessageで受け取るアクション名
 * @param timeout タイムアウト時間(ms)
 * @returns postMessageを受け取るもしくはタイムアウト経過後にIframeを返すPromise または対象が存在しない場合はvoid
 */
export const asyncIframeLoads = (url: string, actionName: "signout" | "storage-onload", timeout = 8000) => {
  return new Promise<HTMLIFrameElement | void>((resolve) => {
    // 対象が存在しない場合はiframeを作らない
    const origin = getOrigin(url);
    if (!origin) {
      resolve();
      return;
    }

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;

    // ログアウト完了時はwindow.postMessageでPromiseを解決する
    window.addEventListener("message", (e) => {
      if (e.origin !== origin) {
        return;
      }
      if (e.data.action === actionName && e.data.status === "done") {
        resolve(iframe);
      }
    });
    // エラー発生時はPromiseを解決する
    iframe.onerror = () => {
      resolve(iframe);
    };

    // ロードされてデフォルトで8秒後には必ずPromiseを解決する
    // 404 の場合はload扱いで、エラー・messageが返ってこないため
    iframe.onload = () => {
      setTimeout(() => resolve(iframe), timeout);
    };
    document.body.appendChild(iframe);
  });
};
