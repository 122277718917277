import Vue from "vue";
import { Amplify } from "aws-amplify";
// import "@aws-amplify/ui-vue";
import { AmplifyPlugin } from "aws-amplify-vue";
import config from "@/config";

Amplify.configure({
  Auth: {
    region: config.aws.region,
    userPoolId: config.aws.cognitoUserPoolId,
    userPoolWebClientId: config.aws.cognitoUserPoolWebClientId
  }
});
Vue.use(AmplifyPlugin, Amplify);
