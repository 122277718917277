import * as winston from "winston";

type Param = {
  name: string;
  customLevel: string;
  position: string;
  msg: string;
  error: any;
};
export class Logger {
  private static logger: winston.Logger;

  static setupCustomField = winston.format((info) => {
    if (info.error instanceof Error) {
      return Object.assign({}, info, {
        stack: info.stack,
        message: info.message
      });
    }
    return info;
  });

  static printf(info: any) {
    const param = info.message as unknown as Param;
    let printMsg = `[${info.timestamp}] `;
    printMsg += param.customLevel ? `${param.customLevel.toUpperCase()} ` : `${info.level.toUpperCase()} `;
    printMsg += "- ";
    printMsg += `[${param.name || "***"}] `;
    let position = param.position || "";
    if (position === "start") {
      position = "**** START ****";
    }
    if (position === "end") {
      position = "**** END ****";
    }

    printMsg += `${position} `;
    printMsg += `${printMsg ? "\n" : ""}${param.msg || ""}`;

    if (param.error) {
      printMsg += `${printMsg ? "\n" : ""}${param.error.stack || ""}`;
    }
    return printMsg.replace(/(\r\n|\r)/gm, "\n");
  }

  public static initialize(cateogry: string) {
    Logger.logger = winston.createLogger({
      level: process.env.LOGLEVEL || "error",
      format: winston.format.combine(
        winston.format.timestamp({
          format: "HH:mm:ss.SSS"
        }),
        Logger.setupCustomField(),
        winston.format.prettyPrint(),
        winston.format.printf(Logger.printf)
      ),
      defaultMeta: { service: cateogry },
      transports: new winston.transports.Console({ stderrLevels: ["error"] })
    });
  }

  /**
   * (DEBUG) 開発用ロガー
   * @param message
   */
  public static debug(msg: string = ""): void {
    Logger.logger.debug({ msg });
  }

  /**
   * (INFO)
   * @param name
   * @param message
   */
  public static info(name: string, msg: string): void {
    Logger.logger.info({ name, msg });
  }

  /**
   * (INFO) メソッド開始時に呼び出す
   * @param name ファイル名およびメソッド名
   *  ex) pages/hode.vue#setup
   * @param message メッセージ
   */
  public static start(name: string, msg: string = ""): void {
    Logger.logger.info({ name, position: "start", msg });
  }

  /**
   * (INFO) メソッド終了時に呼び出す
   * @param name ファイル名およびメソッド名
   * @param message メッセージ
   */
  public static end(name: string, msg: string = ""): void {
    Logger.logger.info({ name, position: "end", msg });
  }

  /**
   * (WARN) 条件付き正常や警告時に呼び出す
   * @param name
   * @param message
   */
  public static warn(name: string, msg: string): void {
    Logger.logger.warn({ name, msg });
  }

  /**
   * (ERROR) 業務エラー時に呼び出す
   * @param name
   * @param message
   * @param e
   */
  public static error(name: string, msg: string, error: Error | undefined = undefined): void {
    Logger.logger.error({ name, msg, error });
  }

  /**
   * (FATAL) システムエラー時に呼び出す
   * @param name
   * @param message
   * @param e
   */
  public static fatal(name: string, msg: string, error: Error): void {
    Logger.logger.error({ name, msg, error, customLevel: "fatal" });
  }
}
