import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _355092f6 = () => interopDefault(import('../pages/ems/index.vue' /* webpackChunkName: "pages/ems/index" */))
const _a748f486 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _8c54a9aa = () => interopDefault(import('../pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _dfe0c11a = () => interopDefault(import('../pages/storage-onload/index.vue' /* webpackChunkName: "pages/storage-onload/index" */))
const _f4466136 = () => interopDefault(import('../pages/ems/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ems",
    component: _355092f6,
    name: "ems"
  }, {
    path: "/private",
    component: _a748f486,
    name: "private"
  }, {
    path: "/signout",
    component: _8c54a9aa,
    name: "signout"
  }, {
    path: "/storage-onload",
    component: _dfe0c11a,
    name: "storage-onload"
  }, {
    path: "/",
    component: _f4466136,
    name: "root-redirect"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
