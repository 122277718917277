import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { AxiosRequestConfig } from "axios";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import FetchError from "@/errors/FetchError";
import { generateUrl } from "@/utils/urlUtils";
import { htmlUnescape } from "@/utils/stringUtils";
import { Logger } from "@/utils/logger";
import { OtherDataState, Catalog } from "@/types/front";
import config from "@/config";

export const PrivateDataSchemaRepository = ($axios: NuxtAxiosInstance) => ({
  /** getメソッド */
  async get(props: { schemaSource: Catalog["schemaSource"]; idToken: CognitoIdToken; config?: AxiosRequestConfig }) {
    const vueName = "composables/repositories/privateDataSchemaRepository.ts";

    const fetchConfig = {
      headers: {
        Authorization: props.idToken.getJwtToken()
      },
      ...(props.config || {})
    };
    const query: Record<string, string> = props.schemaSource.params.reduce((accum, current) => {
      accum[current.key] = current.value;
      return accum;
    }, {} as Record<string, string>);

    let columnList: OtherDataState["schemaList"][number]["columnList"] = [];
    const url = htmlUnescape(
      generateUrl({ path: `${config.hammockDataMartApi.endpoint}${props.schemaSource.path}`, query })
    );
    try {
      const result = await $axios.get<{ name: string; type: string }[]>(url, fetchConfig);
      if (result.data) {
        columnList = result.data;
      }
    } catch (e) {
      Logger.error(`${vueName}#get`, `FetchError: ${url}`);
      throw new FetchError(false, e, "PrivateDataSchemaRepository get method is error");
    }
    return columnList;
  }
});
