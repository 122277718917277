/** URL util */
import * as stringUtil from "./stringUtils";

/**
 * URL文字列を生成して返却する
 * @param {string} path - コンテキストパス以降のpathを表す文字列
 * @param {string} queryString | {Object} queryObject - パラメータを表すクエリストリング、もしくはパラメータオブジェクト
 * @param {string} hash - ハッシュ文字列
 * @return {string} URL文字列
 */
export const generateUrl = function generateUrl(args: {
  path: string;
  query?: string | Record<string, string | number | boolean | undefined>;
  hash?: string;
  paramOrderArray?: Array<string>;
}) {
  let path = args.path;
  const query = args.query || "";
  let hash = args.hash || "";
  const paramOrder = args.paramOrderArray || [];
  let queryString = ""; // query が Object かどうかの判定

  if (typeof query === "object" && Object.prototype.toString.call(query) === "[object Object]") {
    queryString = makeQueryString(query, paramOrder);
  } else if (typeof query === "string") {
    queryString = query;
  } else {
    throw new TypeError("query should be 'String' or 'Object'");
  }

  queryString = queryString ? `?${stringUtil.htmlEscape(queryString)}` : "";
  hash = hash ? `#${encodeURIComponent(hash)}` : "";
  const isPathEndWithSlash = path.slice(-1) === "/"; // http://{host}/{context}//{path} のように/が二重にならないようにする

  if (isPathEndWithSlash) {
    path = path.slice(0, path.length - 1);
  }

  return path + queryString + hash;
};

/**
 * オブジェクトをクエリ文字列にして返す
 * @param {Object} queryObject オブジェクト
 * @param {Array} paramOrder パラメタ名のリスト（指定したパラメタはクエリ文字列上の先頭に、配列の要素順に出現する）
 */

export const makeQueryString = function makeQueryString(
  queryObject: Record<string, string | number | boolean | undefined>,
  paramOrderArray?: Array<string>
) {
  const paramOrder = paramOrderArray || [];

  const _isInvalidValue = (value: any) => {
    return typeof value === "undefined" || value === null || value === "";
  };

  const queryKeys = paramOrder.concat(
    Object.keys(queryObject).filter((key) => {
      return !paramOrder.includes(key);
    })
  );
  const encodedQueryStrings: Array<string> = [];
  queryKeys.forEach((key) => {
    if (_isInvalidValue(queryObject[key])) {
      return;
    }

    encodedQueryStrings.push(`${key}=${encodeURIComponent(queryObject[key]!)}`);
  });
  return encodedQueryStrings.join("&");
};

export const queryStringToObject = function queryStringToObject(qstring: string) {
  return qstring
    ? qstring.split("&").reduce((prev, item) => {
        const keyValue = item.split("=");
        prev[keyValue[0].trim()] = keyValue[1] ? keyValue[1].trim() : "";
        return prev;
      }, {} as { [key: string]: string })
    : null;
};

/**
 * URLにあたる文字列からoriginを解析する
 * @param url URLに該当する文字列
 * @returns urlであればoriginを、解決出来ない場合は空文字を返す
 */
export const getOrigin = (url: string) => {
  if (!url) {
    return "";
  }
  return new URL(url).origin;
};
