import { NuxtAxiosInstance } from "@nuxtjs/axios";
import FetchError from "@/errors/FetchError";
import { Logger } from "@/utils/logger";
import { components } from "@/types/datamartDefinitionSpec";
import { DatamartDataGroup, DatamartOtherData } from "@/types/front";

const vueName = "composables/repositories/privateDataDefRepository.ts";

export const PrivateDataDefRepository = (_$axios: NuxtAxiosInstance) => ({
  get(): Array<DatamartDataGroup | DatamartOtherData> {
    // datamart定義
    let data: components["schemas"]["data_list"] = [];
    try {
      // MEMO: ディレクトリパス部分は変数にすると正常に読み込めない
      data = require("@/static/hammock-datamart-definitions/privateDataList.yaml");
    } catch (e) {
      Logger.error(`${vueName}#get`, `FIleNotFound: @/static/hammock-datamart-definitions/privateDataList.yaml`);
      // 処理を終了する
      throw new FetchError(false, e, "PrivateDataDefRepository get method is error");
    }

    const dataList: Array<DatamartDataGroup | DatamartOtherData> = data.map((d) => {
      // グループの場合
      if ("data" in d) {
        const childrenData = d.data.map((cd) => {
          // データカタログ用の付加情報
          const catalog = getCatalogInfo(cd.id);
          return convert2Data(cd, catalog);
        });
        return {
          type: "group",
          dataId: d.id,
          label: d.label,
          dataList: childrenData
        };
      }
      // データの場合
      // データカタログ用の付加情報
      const catalog = getCatalogInfo(d.id);
      return convert2Data(d, catalog);
    });

    return dataList;
  }
});

const getCatalogInfo = (id: string) => {
  // データカタログ用の付加情報
  let catalog: components["schemas"]["catalog"] = {} as components["schemas"]["catalog"];
  try {
    // MEMO: ディレクトリパス部分は変数にすると正常に読み込めなくなるためハードコードとする
    catalog = require(`@/static/hammock-datamart-definitions/privateData/${id}.yaml`);
  } catch (e) {
    // ファイルがない場合はログを出力して処理を継続させる
    Logger.error(`${vueName}#get`, `FileNotFound: @/static/hammock-datamart-definitions/privateData/${id}.yaml`);
  }
  return catalog;
};

const convert2Data = (
  data: components["schemas"]["data"],
  catalog: components["schemas"]["catalog"]
): DatamartOtherData => {
  return {
    type: "data",
    dataId: data.id,
    label: data.label,
    source: data.source,
    store: data.store,
    catalog: {
      schemaSource: {
        path: catalog.schema_source.path,
        params: catalog.schema_source.params || []
      },
      previewSource: {
        path: catalog.preview_source.path,
        params: catalog.preview_source.params || []
      },
      columnList: catalog.columns || []
    }
  };
};
