import { InjectionKey, reactive } from "@nuxtjs/composition-api";
import { Auth } from "@aws-amplify/auth";
import { CognitoAccessToken, CognitoIdToken } from "amazon-cognito-identity-js";
import { NuxtAppOptions } from "@nuxt/types";
import { AdminApiUser } from "@/types/front";

/** ストア内部のデータ構造の型定義 */
export type UserState = {
  tenantId: string;
  name: string;
  email: string;
  idToken: CognitoIdToken | undefined;
  accessToken: CognitoAccessToken | undefined;
  auth: AdminApiUser | undefined;
};

const UserStore = () => {
  /** ストア内部のデータ */
  const state = reactive<UserState>({
    tenantId: "",
    name: "",
    email: "",
    idToken: undefined,
    accessToken: undefined,
    auth: undefined
  });

  async function initStore(props: { app: NuxtAppOptions }) {
    /** Amplify認証情報を取得する */
    const authRes = await Auth.currentAuthenticatedUser({ bypassCache: true });
    state.email = authRes.attributes.email;
    state.name =
      authRes.attributes.family_name && authRes.attributes.given_name
        ? `${authRes.attributes.family_name} ${authRes.attributes.given_name}`
        : state.email.split("@")[0];
    state.tenantId = authRes.attributes.profile;
    const cognitoSession = await Auth.currentSession();
    if (cognitoSession) {
      const accessToken = cognitoSession.getAccessToken();
      const idToken = cognitoSession.getIdToken();
      setToken({ idToken, accessToken });
    }
    await fetchAdminAuthUser({ app: props.app });
  }

  function setToken(props: { idToken?: CognitoIdToken; accessToken?: CognitoIdToken }) {
    // ストアにデータ設定
    state.idToken = props.idToken ? props.idToken : state.idToken;
    state.accessToken = props.accessToken ? props.accessToken : state.accessToken;
  }

  async function fetchAdminAuthUser(props: { app: NuxtAppOptions }) {
    if (state.accessToken && state.idToken && state.email && state.email !== "") {
      // データ取得
      const userData = await props.app
        .$repositories("user")
        .getUser({ email: state.email, idToken: state.idToken, config: {} });
      if (userData) {
        // ストアにデータ設定
        state.auth = userData;
      }
    }
  }

  function getHasAuthTenantList() {
    let tenantList: string[] = [];
    if (state.auth) {
      const hasTenantAuth = state.auth.customAuth.some((a) => a.app === "datamart" && a.auth === "tenant");
      if (hasTenantAuth) {
        tenantList = state.auth.tenantAuth.map((t) => t.tenant);
      }
    }
    return tenantList;
  }

  function hasAuth(type: string) {
    return !!state.auth && state.auth.customAuth.some((a) => a.app === "datamart" && a.auth === type);
  }

  return {
    data: state,
    initStore,
    setToken,
    fetchAdminAuthUser,
    getHasAuthTenantList,
    hasAuth
  };
};
export default UserStore;
export type UserStoreReturnType = ReturnType<typeof UserStore>;
export const UserStoreInjectionKey: InjectionKey<UserStoreReturnType> = Symbol("UserStore");
