import { InjectionKey, reactive } from "@nuxtjs/composition-api";
import { CognitoIdToken } from "amazon-cognito-identity-js";
import { CancelTokenSource, CancelToken } from "axios";
import { NuxtAppOptions } from "@nuxt/types";
import { LocationHash, Tenant, ComClass, Com, PreviewTab } from "@/types/front";

/** ストア内部のデータ構造の型定義 */
export type EmsState = {
  /** locationのHash情報 */
  locationHash: LocationHash;
  /** テナント一覧情報 */
  tenantList: Array<Omit<Tenant, "comList"> & { comList: Array<Com & { class?: ComClass }> }>;
  /** プレビューデータ情報 */
  previewData: Array<Record<string, string | number | boolean>>;
  /** プレビューデータFetch用キャンセルトークン */
  previewDataCancelToken: CancelTokenSource | undefined;
};

const EmsStore = () => {
  /** ストア内部のデータ */
  const state = reactive<EmsState>({
    locationHash: {},
    tenantList: [],
    previewData: [],
    previewDataCancelToken: undefined
  });

  function fetchLocation(props: { app: NuxtAppOptions }) {
    const { app } = props;
    // データ取得
    const locationHash = app.$repositories("locationHash").get();

    // ストアにデータ設定
    state.locationHash = locationHash;
  }

  function fetchEms(props: { app: NuxtAppOptions; hasAuthTenantList: string[] }) {
    const { app } = props;
    // データ取得
    const resTenantComList = app.$repositories("tenantComList").get(props.hasAuthTenantList);
    // COM種別情報取得のため重複削除したclass情報の一覧を生成
    const classNameSet = new Set<string>();
    resTenantComList.forEach((tenant) => {
      tenant.comList.forEach((com) => {
        classNameSet.add(com.className);
      });
    });
    const classNameList = [...classNameSet];
    const resComClassHash = app.$repositories("comClassHash").get(classNameList);

    const tenantList = resTenantComList.map((tenant) => {
      const comList = tenant.comList.map((com) => {
        const comClass: ComClass | undefined = resComClassHash[com.className];
        return { ...com, class: comClass };
      });
      return { ...tenant, comList };
    });

    // ストアにデータ設定
    state.tenantList = tenantList;
  }

  async function fetchEmsPreview(props: {
    app: NuxtAppOptions;
    endpoint: PreviewTab["endpoint"];
    idToken: CognitoIdToken;
    params: PreviewTab["params"];
  }) {
    // すでに実行中のFetch処理の有無を確認
    if (state.previewDataCancelToken) {
      // キャンセル処理
      props.app.$repositories("comPreviewData").cancel(state.previewDataCancelToken as CancelTokenSource);
    }
    // キャンセルトークン生成
    state.previewDataCancelToken = props.app.$repositories("comPreviewData").createCancelToken();

    // データ取得
    const previewData = await props.app
      .$repositories("comPreviewData")
      .get({ ...props, config: { cancelToken: state.previewDataCancelToken?.token as CancelToken } });
    // ストアにデータ設定
    state.previewData = previewData;
    state.previewDataCancelToken = undefined;
  }

  return {
    data: state,
    fetchLocation,
    fetchEms,
    fetchEmsPreview
  };
};
export default EmsStore;
export type EmsStoreReturnType = ReturnType<typeof EmsStore>;
export const EmsStoreInjectionKey: InjectionKey<EmsStoreReturnType> = Symbol("EmsStore");
