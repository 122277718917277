//
//
//
//

import { defineComponent, onMounted } from "@vue/composition-api";
import { Auth } from "@aws-amplify/auth";
import config from "@/config";
import { getOrigin } from "@/utils/urlUtils";

export default defineComponent({
  setup() {
    onMounted(() => {
      const signOut = async () => {
        await Auth.signOut();
        window.parent.postMessage({ action: "signout", status: "done" }, getOrigin(config.portalUrl));
      };

      signOut();
    });

    return {};
  }
});
