import { UserRepository } from "../repositories/userRepository";
import { LocationRepository } from "../repositories/locationRepository";
import { TenantComRepository } from "../repositories/tenantComRepository";
import { ComClassRepository } from "../repositories/comClassRepository";
import { ComPreviewRepository } from "../repositories/comPreviewRepository";
import { PrivateDataDefRepository } from "../repositories/privateDataDefRepository";
import { PrivateDataSchemaRepository } from "../repositories/privateDataSchemaRepository";
import { PrivateDataPreviewRepository } from "../repositories/privateDataPreviewRepository";

export interface Repositories {
  user: typeof UserRepository;
  locationHash: typeof LocationRepository;
  tenantComList: typeof TenantComRepository;
  comClassHash: typeof ComClassRepository;
  comPreviewData: typeof ComPreviewRepository;
  privateDataDef: typeof PrivateDataDefRepository;
  privateDataSchema: typeof PrivateDataSchemaRepository;
  privatePreviewData: typeof PrivateDataPreviewRepository;
}

const repositories: Repositories = {
  user: UserRepository,
  locationHash: LocationRepository,
  tenantComList: TenantComRepository,
  comClassHash: ComClassRepository,
  comPreviewData: ComPreviewRepository,
  privateDataDef: PrivateDataDefRepository,
  privateDataSchema: PrivateDataSchemaRepository,
  privatePreviewData: PrivateDataPreviewRepository
};

export const repositoryFactory = {
  get: (key: keyof Repositories) => repositories[key]
};
