import ExtensibleCustomError from "extensible-custom-error";

class FetchError extends ExtensibleCustomError {
  isCancel: boolean;

  constructor(isCancel: boolean, error?: unknown, message?: string) {
    if (!error || !(error instanceof Error)) {
      super(new Error(`${message || "error is unknown"}`));
    } else {
      super(`${message || "error is unknown"}`, error);
    }
    this.isCancel = isCancel;
  }
}
export default FetchError;
