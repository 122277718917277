import { NuxtAxiosInstance } from "@nuxtjs/axios";
import FetchError from "@/errors/FetchError";
import { Logger } from "@/utils/logger";
import { components } from "@/types/deviceDefinitionSpec";
import { Tenant } from "@/types/front";

export const TenantComRepository = (_$axios: NuxtAxiosInstance) => ({
  get(hasAuthTenantList: string[]): Tenant[] {
    const vueName = "composables/repositories/tenantComRepository.ts";

    // TODO: テナント一覧の情報をAdminAPIのlistTenantsから取得するよう変更
    // テナント一覧定義
    let tenants: components["schemas"]["tenants"] = [];
    try {
      // MEMO: ディレクトリパス部分は変数にすると正常に読み込めない
      tenants = require("@/static/device-definitions/tenants.yaml");
    } catch (e) {
      Logger.error(`${vueName}#get`, `FIleNotFound: @/static/device-definitions/tenants.yaml`);
      // 処理を終了する
      throw new FetchError(false, e, "TenantComRepository get method is error");
    }

    const tenantList: Tenant[] = tenants
      .filter((tenant) => hasAuthTenantList.includes(tenant.id))
      .map((tenant) => {
        // COM一覧定義
        let coms: components["schemas"]["comids"] = [];
        try {
          // MEMO: ディレクトリパス部分は変数にすると正常に読み込めなくなるためハードコードとする
          coms = require(`@/static/device-definitions/comids/${tenant.id}.yaml`);
        } catch (e) {
          // ファイルがない場合はログを出力して処理を継続させる
          Logger.info(`${vueName}#get`, `FIleNotFound: @/static/device-definitions/comids/${tenant.id}.yaml`);
        }
        return {
          tenantId: tenant.id,
          label: tenant.label,
          locations: tenant.locations || [],
          comList:
            coms?.map((com) => ({
              comId: com.id,
              label: com.label,
              deviceId: com.device_id,
              location: com.location,
              className: com.class,
              controlled: com.controlled,
              category: com.category,
              flow: com.flow,
              manufacturer: com.manufacturer,
              edited: com.edited,
              annotations: com.annotations
            })) || []
        };
      });

    return tenantList;
  }
});
