import { Inject, NuxtApp } from "@nuxt/types/app";

import { repositoryFactory, Repositories } from "@/composables/factories/repositoryFactory";

export default ({ app }: { app: NuxtApp }, inject: Inject) => {
  const repositories = (name: keyof Repositories) => {
    return repositoryFactory.get(name)(app.$axios);
  };
  inject("repositories", repositories);
};
