/** 文字列util */

const _htmlEscapeMap: Record<string, string> = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#x27;",
  "`": "&#x60;"
};
const _htmlUnescapeMap: Record<string, string> = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#x27;": "'",
  "&#x60;": "`"
};

const _createEscaper = function _createEscaper(map: Record<string, string>) {
  const escaper = function escaper(match: string) {
    return map[match];
  };

  const source = `(?:${Object.keys(map).join("|")})`;
  const testRegexp = RegExp(source);
  const replaceRegexp = RegExp(source, "g");
  return (string: string) => {
    string = string == null ? "" : `${string}`;
    return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
  };
};

/**
 * 受け取った文字列をhtmlEscapeして返却する
 * escape対象文字列のマッピングは_htmlEscapMapで定義
 *
 * @param {string} string - escapeする文字列
 * @return {string} escape済の文字列
 */
export const htmlEscape = _createEscaper(_htmlEscapeMap);

export const htmlUnescape = _createEscaper(_htmlUnescapeMap);

/**
 * 数値をカンマ(,)で桁区切りする.
 * @memberof string-util
 *
 * @param {number} num - 桁区切りされる数値.
 * @return {string} 桁区切りされた、数値を表す文字列.
 */
export const commify = function commify(num: number) {
  let res = "";
  const ary = String(num).split(".");
  const n = Number(ary[0]);
  const intNum = String(Math.abs(n));
  const len = intNum.length;

  for (let idx = 0; idx < len; idx++) {
    if (idx > 0 && (idx - len) % 3 === 0) {
      res += ",";
    }

    res += intNum.charAt(idx);
  }

  ary[0] = res;
  return (_isNegative(num) ? "-" : "") + ary.join(".");
};

const _isNegative = function _isNegative(num: number) {
  return num.toString().startsWith("-");
};

/**
 * from, to をdelimiterで区切った文字列を返却する
 * from, to が同一の場合はfromのみを返却する
 * from, to いずれかの引数しか渡されなかった場合はいずれかのみを返却する
 *
 * @param {string} from - from文字列（数値の入力も考慮）
 * @param {string} to - to文字列（数値の入力も考慮）
 * @param {string} delimiter - delimiter文字列
 * @return {string} from, to をdelimiterで区切った文字列 もしくはfrom, toいずれかの文字列
 */
export const makeFromToText = function makeFromToText(from: string, to: string, delimiterStr?: string) {
  const delimiter = delimiterStr || "～";
  from = from == null ? "" : String(from);
  to = to == null ? "" : String(to);

  if (!from && !to) {
    return "";
  }

  if (!from) {
    return to;
  }

  if (!to) {
    return from;
  }

  if (from === to) {
    return "".concat(from);
  }
  return "".concat(from).concat(delimiter).concat(to);
};

/**
 * 日付表現文字列 yyyymmdd から yyyy/mm/dd を返却する
 *
 * @param {string} text - yyyymmddd
 * @param {string} [delimiter=/] - delimiter 文字列
 * @return {string} yyyy/mm/dd 文字列
 */

export const makeDelimitedDateText = function makeDelimitedDateText(yyyymmdd: string, delimiterStr?: string) {
  const delimiter = delimiterStr || "/";

  if (!yyyymmdd || yyyymmdd.length !== 8) {
    return "";
  }

  const dateText = yyyymmdd.replace(/(\\d{4})(\\d{2})(\\d{2})/g, "$1".concat(delimiter, "$2").concat(delimiter, "$3"));
  return dateText;
};
