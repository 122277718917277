import { NuxtAxiosInstance } from "@nuxtjs/axios";
import FetchError from "@/errors/FetchError";
import { Logger } from "@/utils/logger";
import { components } from "@/types/deviceDefinitionSpec";
import { LocationHash } from "@/types/front";

export const LocationRepository = (_$axios: NuxtAxiosInstance) => ({
  get(): LocationHash {
    const vueName = "composables/repositories/locationRepository.ts";

    let locationList: components["schemas"]["locations"] = [];
    try {
      // MEMO: ディレクトリパス部分は変数にすると正常に読み込めない
      locationList = require("@/static/device-definitions/locations.yaml");
    } catch (e) {
      Logger.error(`${vueName}#get`, `FIleNotFound: @/static/device-definitions/locations.yaml`);
      // 処理を終了する
      throw new FetchError(false, e, "LocationRepository get method is error");
    }
    return locationList.reduce((accum, current) => {
      accum[current.id] = current.label || "";
      return accum;
    }, {} as LocationHash);
  }
});
