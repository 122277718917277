import { Auth } from "@aws-amplify/auth";
import { Middleware } from "@nuxt/types";
import config from "@/config";

const myMiddleware: Middleware = async (context) => {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (error) {
    return context.redirect(config.portalUrl + "login?return=datamart");
  }
};
export default myMiddleware;
